import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CashBookSummary } from '../models/CashBookSummary';

@Injectable({
  providedIn: 'root'
})
export class CashBookService {


  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = environment.BaseServiceUrl + "CashBook";

  public getCashBookSummary(): Observable<CashBookSummary> {
    let url = this.ServiceUrl + '/Summary';
    return this.httpClient.get<CashBookSummary>(url);
  }
}
