import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelHistoryData } from 'src/app/models/excel-history-data';
import { StockAnalysis } from 'src/app/models/stock-analysis';
import { StockAnalysisService } from 'src/app/services/stock-analysis.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-excel-back-test',
  templateUrl: './excel-back-test.component.html',
  styleUrls: ['./excel-back-test.component.css']
})
export class ExcelBackTestComponent implements OnInit {

  constructor(
    private stockAnalysisService: StockAnalysisService,
    private spinnerService: NgxSpinnerService) { }


  errorMsg: string;
  hrDataJsonArray: any[] = [];
  stockAnalysisData: StockAnalysis;

  ngOnInit() {
  }

  loadStockAnalysis() {
    var historyDataList = this.getExcelHistoryData();

    let historyDataString = JSON.stringify(historyDataList);

    this.stockAnalysisData = null;
    this.spinnerService.show();
    this.stockAnalysisService.getAnalysisReportByExcel(historyDataList).subscribe(
      data => {
        this.stockAnalysisData = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }


  totalTradePoints() {
    return this.stockAnalysisData.TradePositions.reduce((a, b) => a + b.TradePoints, 0);
  }

  totalROI() {
    return this.stockAnalysisData.TradePositions.reduce((a, b) => a + b.ROI, 0);
  }


  totalTradeDays() {
    return (this.stockAnalysisData.TradePositions.reduce((a, b) => a + b.TotalTradeDays, 0) / 365);
  }

  totalROIPerYear() {
    var totalYear = this.totalTradeDays();
    if (totalYear < 1) {
      return this.totalROI();
    }
    return this.totalROI() / this.totalTradeDays();
  }



  addExcelFile(event) {

    this.hrDataJsonArray = [];
    this.stockAnalysisData = null;
    let file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      
      let sheetNames=Object.keys(workbook.Sheets);
      let first_sheet_name = sheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      this.hrDataJsonArray = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        header: 1,
        dateNF: 'dd/mm/yyyy',
      });
    };
  }

  getExcelHistoryData() {
    let dateIndex = 0;
    let openIndex = 1;
    let highIndex = 2;
    let lowIndex = 3;
    let closeIndex = 4;

    let historyDataList: ExcelHistoryData[] = [];

    for (let i = 1; i < this.hrDataJsonArray.length; i++) {
      let historyJsonData = this.hrDataJsonArray[i];

      if (
        !historyJsonData[dateIndex]
      ) {
        continue;
      }

      let historyData: ExcelHistoryData = {
        Date: this.ExcelDate(historyJsonData[dateIndex]),
        Open: historyJsonData[openIndex],
        High: historyJsonData[highIndex],
        Low: historyJsonData[lowIndex],
        Close: historyJsonData[closeIndex]
      };
      historyDataList.push(historyData);
    }

    return historyDataList;
  }

  ExcelDate(dateString: any) {
    return isNaN(dateString)
      ? new Date(dateString)
      : this.ExcelDateToJSDate(dateString);
  }

  ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
  }


}
