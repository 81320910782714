import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockAnalysis } from 'src/app/models/stock-analysis';
import { StockAnalysisService } from 'src/app/services/stock-analysis.service';

@Component({
  selector: 'app-stock-analysis-report',
  templateUrl: './stock-analysis-report.component.html',
  styleUrls: ['./stock-analysis-report.component.css']
})
export class StockAnalysisReportComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private stockAnalysisService: StockAnalysisService,
    private spinnerService: NgxSpinnerService) { }


  searchForm: FormGroup;
  errorMsg: string;
  stockAnalysisReport: StockAnalysis[] = [];
  tradeReports: any[] = [];
  tradePoints = {};
  tradeDays = {};
  tradeROI = {};
  tradeROIPerYear = {};
  totalTradePoints: number = 0;
  totalTradeDays: number = 0;
  totalROI: number = 0;
  totalAverageReturn: number = 0;
  timePeriod: number = 0;
  returnPerYear: number = 0;


  ngOnInit() {
    this.createSearchForm();
  }


  loadStockAnalysis() {
    this.resetValues();
    let searchValue = this.searchForm.value;
    this.spinnerService.show();
    this.stockAnalysisService.getReport(searchValue).subscribe(
      data => {
        this.stockAnalysisReport = data;
        this.setTradePointsAndDays(this.stockAnalysisReport);
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  resetValues() {
    this.stockAnalysisReport = [];
    this.tradePoints = {};
    this.tradeDays = {};
    this.tradeROI = {};
    this.tradeROIPerYear = {};
    this.totalTradePoints = 0;
    this.totalTradeDays = 0;
    this.totalROI = 0;
    this.totalAverageReturn = 0;
    this.timePeriod = 0;
    this.returnPerYear = 0;
    this.tradeReports = [];
  }

  setTradePointsAndDays(stockAnalysisReport: StockAnalysis[]) {

    stockAnalysisReport.forEach(x => {
      this.tradePoints[x.StockName] = x.TradePositions.reduce((pre, curr) => pre + curr.TradePoints, 0);
      this.tradeDays[x.StockName] = x.TradePositions.reduce((pre, curr) => pre + curr.TotalTradeDays, 0);
      this.tradeROI[x.StockName] = x.TradePositions.reduce((pre, curr) => pre + curr.ROI, 0);

      let tradeYear = this.tradeDays[x.StockName] / 365;
      this.tradeROIPerYear[x.StockName] = (tradeYear < 1) ? this.tradeROI[x.StockName] : (this.tradeROI[x.StockName] / tradeYear);

      this.totalTradePoints += this.tradePoints[x.StockName];
      this.totalTradeDays += this.tradeDays[x.StockName];
      this.totalROI += this.tradeROI[x.StockName];

      x.TradePositions.forEach(tr=>{
        this.tradeReports.push({
          StockName:x.StockName,
          Position: tr.Position,
          EntryDateTime: tr.EntryDateTime,
          Entry: tr.Entry,
          SL: tr.SL,
          SLStatusDateTime: tr.SLStatusDateTime,
          SLType: tr.SLType,
          ExitDateTime: tr.ExitDateTime,
          Exit: tr.Exit,
          TradePoints: tr.TradePoints,
          ROI: tr.ROI,
          TotalTradeDays:tr.TotalTradeDays,
          Comment: tr.Comment,
          OtherInfo:x.OtherInfo
        })
      });
    });
    this.totalAverageReturn = this.totalROI / this.stockAnalysisReport.length;
    this.timePeriod = this.diff_years();
    this.returnPerYear = (this.timePeriod < 1) ? this.totalAverageReturn : (this.totalAverageReturn / this.timePeriod);
  }

  diff_years() {
    let fromDate = new Date(this.searchForm.value.FromDate);
    let toDate = new Date(this.searchForm.value.ToDate);
    var diff = (fromDate.getTime() - toDate.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    return Math.abs(Math.round(diff / 365.25));
  }


  getTradePoints(stockAnalysis: StockAnalysis) {
    return stockAnalysis.TradePositions.reduce((pre, curr) => pre + curr.TradePoints, 0);
  }

  getTradeDays(stockAnalysis: StockAnalysis) {
    return stockAnalysis.TradePositions.reduce((pre, curr) => pre + curr.TotalTradeDays, 0);
  }

  getDateTimeFormat() {
    return 'dd-MMM-yy hh:mm';
  }




  createSearchForm() {
    let fromDate = new Date(2023, 4, 1);
    let toDate = new Date();

    // console.log(fromDate.toString());

    this.searchForm = this.formBuilder.group({
      FromDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
      ToDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required]
    })
  }

}
