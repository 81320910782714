import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, timer } from 'rxjs';
import { CashBookSummary } from 'src/app/models/CashBookSummary';
import { LiveCompletedPosition } from 'src/app/models/LiveCompletedPosition';
import { CompletedPosition } from 'src/app/models/completed-position';
import { LivePosition } from 'src/app/models/live-position';
import { CashBookService } from 'src/app/services/cash-book.service';
import { CompletedPositionService } from 'src/app/services/completed-position.service';
import { LiveCompletedPositionService } from 'src/app/services/live-completed-position.service';
import { LivePositionService } from 'src/app/services/live-position.service';

@Component({
  selector: 'app-equity',
  templateUrl: './equity.component.html',
  styleUrls: ['./equity.component.css']
})
export class EquityComponent implements OnInit, OnDestroy {

  constructor(private livePositionService: LivePositionService,
    private completedPositionService: CompletedPositionService,
    private liveCompletePositionService: LiveCompletedPositionService,
    private cashBookService: CashBookService,
    private spinnerService: NgxSpinnerService) { }

  showReportType: string;
  livePositions: LivePosition[] = [];
  completedPositions: CompletedPosition[] = [];
  liveCompletedPositions: LiveCompletedPosition[] = [];
  cashBookSummary: CashBookSummary;
  errorMsg: string = "";
  currentDate: Date;
  subscription: Subscription;

  ngOnInit() {
    this.showReportType = "All Position";
    this.currentDate = new Date();

    this.subscription = timer(0, 1000).subscribe(data => {
      this.currentDate = new Date();
    });

    this.loadPositions();
  }

  loadPositions() {
    this.spinnerService.show();

    let liveCompletedPositionRequest = this.liveCompletePositionService.getLiveCompletedPositions();
    let cashBookSummaryRequest = this.cashBookService.getCashBookSummary();

    forkJoin([liveCompletedPositionRequest, cashBookSummaryRequest])
      .subscribe(
        data => {
          this.liveCompletedPositions = data[0];
          this.cashBookSummary = data[1];
         // this.updateTodayProfit();
        },
        err => {
          this.errorMsg = err;
          this.spinnerService.hide();
        },
        () => this.spinnerService.hide()
      )
  }

  private updateTodayProfit() {
    let totalProfit = this.liveCompletedPositions.map(x => x.ProfitOrLoss).reduce((a, b) => a + b);
    this.cashBookSummary.TodaysProfit = this.cashBookSummary.TotalCurrentValue - totalProfit;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
