import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { StockAnalysis, StockAnalysisRequest } from '../models/stock-analysis';
import { DateRangeRequest } from '../models/date-range-request';
import { ExcelHistoryData } from '../models/excel-history-data';

@Injectable({
  providedIn: 'root'
})
export class StockAnalysisService {


  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = environment.BaseServiceUrl + "StockAnalysis";

  public get(request: StockAnalysisRequest): Observable<StockAnalysis> {
    let url = this.ServiceUrl;
    return this.httpClient.post<StockAnalysis>(url, request);
  }

  public getReport(request: DateRangeRequest): Observable<StockAnalysis[]> {
    let url = this.ServiceUrl + "/AnalysisReport?FromDate=" + request.FromDate + "&ToDate=" + request.ToDate
      + "&StockCode=0&TimeInterval=60minute";
    return this.httpClient.get<StockAnalysis[]>(url);
  }

  public getAnalysisReportByExcel(request: ExcelHistoryData[]): Observable<StockAnalysis> {
    let url = this.ServiceUrl + "/AnalysisReport/Excel";
    return this.httpClient.post<StockAnalysis>(url, request);
  }
}
