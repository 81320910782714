import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { StockConfig } from 'src/app/models/stock-config';
import { StockScanRespose } from 'src/app/models/stock-scan-respose';
import { LiveTradeService } from 'src/app/services/live-trade.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scan-stock-today-entry',
  templateUrl: './scan-stock-today-entry.component.html',
  styleUrls: ['./scan-stock-today-entry.component.css']
})
export class ScanStockTodayEntryComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private liveTradeService: LiveTradeService,
    private spinnerService: NgxSpinnerService) { }

  searchForm: FormGroup;
  errorMsg: string;
  stockScanResponse:StockScanRespose;
  stockConfigList: StockConfig[] = [];

  ngOnInit() {
    this.createSearchForm();
  }

  loadTodayStockToBuy() {
    var searchValue = this.searchForm.value;
    this.errorMsg = "";
    this.spinnerService.show();
    this.liveTradeService.get(searchValue).subscribe(
      data => {
        this.stockScanResponse = data;
        if (!this.stockScanResponse.IsSuccess) {
          this.errorMsg = this.stockScanResponse.ErrorMsg;
        }
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      UserId: ['', Validators.required],
      Token: ['', Validators.required],
    })
  }

}
