import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LivePosition } from '../models/live-position';

@Injectable({
  providedIn: 'root'
})
export class LivePositionService {


  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = environment.BaseServiceUrl + "LivePosition";

  public getAllLongPositions(): Observable<LivePosition[]> {
    let url=this.ServiceUrl+'/long';
    return this.httpClient.get<LivePosition[]>(url);
  }

  public getAllShortPositions(): Observable<LivePosition[]> {
    let url=this.ServiceUrl+'/short';
    return this.httpClient.get<LivePosition[]>(url);
  }
}
