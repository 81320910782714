// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  userId: '',
  token: '',
  kiteStockUrl:'https://kite.zerodha.com/chart/ext/tvc/NSE/',
  BaseServiceUrl: 'https://wealthon.in/apiservice/api/'// 'https://localhost:7006/api/' // // 'http://localhost:8080/' //
 // BaseServiceUrl: 'https://localhost:7006/api/' //'http://localhost:8080/api/' //
};



// set NODE_OPTIONS=--openssl-legacy-provider

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
