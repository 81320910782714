import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TradePosition } from 'src/app/models/trade-position';

@Component({
  selector: 'app-trade-position-grid',
  templateUrl: './trade-position-grid.component.html',
  styleUrls: ['./trade-position-grid.component.css']
})
export class TradePositionGridComponent implements OnInit {

  constructor() { }

  @Input() tradePositions: TradePosition[];

  // totalTradePoints: number = 0;
  // totalROI: number = 0;
  // totalTradeExpectedPoints: number = 0;
  // totalExpectedROI: number = 0;
  // totalTradeDays: number = 0;

  ngOnInit() {
    // this.totalTradePoints = this.tradePositions.reduce((a, b) => a + b.TradePoints, 0);
    // this.totalROI = this.tradePositions.reduce((a, b) => a + b.ROI, 0);
    // this.totalTradeExpectedPoints = this.tradePositions.reduce((a, b) => a + b.ExpectedTradePoints, 0);
    // this.totalExpectedROI = this.tradePositions.reduce((a, b) => a + b.ExpectedROI, 0);
    // this.totalTradeDays = (this.tradePositions.reduce((a, b) => a + b.TotalTradeDays, 0) / 365);
  }

  // totalTradePoints() {
  //   return this.tradePositions.reduce((a, b) => a + b.TradePoints, 0);
  // }

  // totalROI() {
  //   return this.tradePositions.reduce((a, b) => a + b.ROI, 0);
  // }

  // totalTradeExpectedPoints() {
  //   return this.tradePositions.reduce((a, b) => a + b.ExpectedTradePoints, 0);
  // }

  // totalExpectedROI() {
  //   return this.tradePositions.reduce((a, b) => a + b.ExpectedROI, 0);
  // }

  // totalTradeDays(){
  //   return (this.tradePositions.reduce((a, b) => a + b.TotalTradeDays, 0) / 365);
  // }

  // totalROIPerYear(){
  //   return this.totalROI() / this.totalTradeDays();
  // }

}
