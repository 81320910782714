import { Component, Input, OnInit } from '@angular/core';
import { DowData } from 'src/app/models/dow-data';

@Component({
  selector: 'app-dow-data-grid',
  templateUrl: './dow-data-grid.component.html',
  styleUrls: ['./dow-data-grid.component.css']
})
export class DowDataGridComponent implements OnInit {

  constructor() { }

  @Input() dowDatas: DowData[];

  ngOnInit() {
  }

}
