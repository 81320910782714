import { Component, Input, OnInit } from '@angular/core';
import { CashBookSummary } from 'src/app/models/CashBookSummary';
import { LiveCompletedPosition } from 'src/app/models/LiveCompletedPosition';
import { CompletedPosition } from 'src/app/models/completed-position';
import { LivePosition } from 'src/app/models/live-position';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-live-completed-position',
  templateUrl: './live-completed-position.component.html',
  styleUrls: ['./live-completed-position.component.css']
})
export class LiveCompletedPositionComponent implements OnInit {

  constructor() { }


  @Input() liveCompletedPositions: LiveCompletedPosition[];
  @Input() cashBookSummary: CashBookSummary;
  @Input() positionType: string;
  @Input() dateFormat: string;

  errorMsg: string = "";
  kitestockUrl: string;


  ngOnInit() {
    this.kitestockUrl = environment.kiteStockUrl
  }

  getDateFormat() {
    return this.dateFormat != undefined? this.dateFormat : 'dd-MMM-yy';
  }

  getProfitClass(profitOrLoss: number) {

    if (profitOrLoss < 0) {
      return 'loss-class';
    }

    if (profitOrLoss > 0) {
      return 'profit-class';
    }

  }

}
