import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompletedPositionComponent } from './components/completed-position/completed-position.component';
import { DerivativesComponent } from './components/derivatives/derivatives.component';
import { EquityComponent } from './components/equity/equity.component';
import { HomeComponent } from './components/home/home.component';
import { LivePositionComponent } from './components/live-position/live-position.component';
import { ScanStockTodayEntryComponent } from './components/scan-stock-today-entry/scan-stock-today-entry.component';
import { SingleStockAnalysisComponent } from './components/single-stock-analysis/single-stock-analysis.component';
import { CompletedPosition } from './models/completed-position';
import { StockAnalysisReportComponent } from './components/stock-analysis-report/stock-analysis-report.component';
import { HrEquityComponent } from './components/_hr-components/hr-equity/hr-equity.component';
import { ExcelBackTestComponent } from './components/excel-back-test/excel-back-test.component';
import { MyBudgetStockAnalysisComponent } from './components/my-budget-stock-analysis/my-budget-stock-analysis.component';
import { AmzLoginComponent } from './components/_Amazon/amz-login/amz-login.component';


const routes: Routes = [
  {
    path: '',
    component: EquityComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'stock-analysis',
    component: SingleStockAnalysisComponent
  },
  {
    path: 'stock-analysis-report',
    component: StockAnalysisReportComponent
  },
  {
    path: 'equity',
    component: EquityComponent
  },
  {
    path: 'hr-equity',
    component: HrEquityComponent
  },
  {
    path: 'excel-back-test',
    component: ExcelBackTestComponent
  },
  {
    path: 'my-budget-stock',
    component: MyBudgetStockAnalysisComponent
  },
  {
    path: 'derivatives',
    component: DerivativesComponent
  },
  {
    path: 'amzon',
    component: AmzLoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
