import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompletedPosition } from 'src/app/models/completed-position';
import { CompletedPositionService } from 'src/app/services/completed-position.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  constructor(private completedPositionService: CompletedPositionService,
    private spinnerService: NgxSpinnerService) { }

  completedPositions: CompletedPosition[] = [];
  errorMsg: string = "";
  totalReturn: number = 0;
  avgReturn: number = 0;

  ngOnInit() {
  }


  loadCompletedPositions() {

    this.spinnerService.show();
    this.completedPositionService.getAllLongPositions().subscribe(
      data => {
        this.completedPositions = data;
        this.totalReturn = (this.completedPositions.reduce((a, b) => a + b.ProfitAndLoss, 0));
        this.avgReturn = this.totalReturn / this.completedPositions.length;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

}
