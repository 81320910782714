import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StockConfig } from '../models/stock-config';
import { StockScanRespose } from '../models/stock-scan-respose';

@Injectable({
  providedIn: 'root'
})
export class LiveTradeService {


  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = environment.BaseServiceUrl + "LiveTrade";

  public get(entity: any): Observable<StockScanRespose> {
    let url = this.ServiceUrl + "/Today/Stock/Buy";
    return this.httpClient.post<StockScanRespose>(url, entity);
  }
}
