import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LivePosition } from 'src/app/models/live-position';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HrLivePositionService {

  
  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = environment.BaseServiceUrl + "HrLivePosition";

  public getAllLongPositions(): Observable<LivePosition[]> {
    let url=this.ServiceUrl+'/long';
    return this.httpClient.get<LivePosition[]>(url);
  }

  public getAllShortPositions(): Observable<LivePosition[]> {
    let url=this.ServiceUrl+'/short';
    return this.httpClient.get<LivePosition[]>(url);
  }

}
