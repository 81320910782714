import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompletedPosition } from '../models/completed-position';

@Injectable({
  providedIn: 'root'
})
export class CompletedPositionService {

  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = environment.BaseServiceUrl + "CompletedPosition";
  
  public getAllLongPositions(): Observable<CompletedPosition[]> {
    let url=this.ServiceUrl+'/long';
    return this.httpClient.get<CompletedPosition[]>(url);
  }

  public getAllShortPositions(): Observable<CompletedPosition[]> {
    let url=this.ServiceUrl+'/short';
    return this.httpClient.get<CompletedPosition[]>(url);
  }
}
