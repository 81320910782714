import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CompletedPosition } from 'src/app/models/completed-position';
import { CompletedPositionService } from 'src/app/services/completed-position.service';

@Component({
  selector: 'app-completed-position',
  templateUrl: './completed-position.component.html',
  styleUrls: ['./completed-position.component.css']
})
export class CompletedPositionComponent implements OnInit {

  constructor() { }

  @Input() completedPositions: CompletedPosition[];
  @Input() positionType:string;

  errorMsg: string = "";

  ngOnInit() {
  }

  calculateAvgReturns() {
    return (this.completedPositions.reduce((a, b) => a + b.ProfitAndLoss, 0)) / this.completedPositions.length;
  }

  getDateFormat() {
    return 'dd-MMM-yy';
  }


}
