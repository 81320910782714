import { Component, Input, OnInit } from '@angular/core';
import { HistoryData } from 'src/app/models/history-data';

@Component({
  selector: 'app-history-data-grid',
  templateUrl: './history-data-grid.component.html',
  styleUrls: ['./history-data-grid.component.css']
})
export class HistoryDataGridComponent implements OnInit {

  constructor() { }

  @Input() historyDatas: HistoryData[];

  ngOnInit() {
  }

}
