import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CashBookSummary } from 'src/app/models/CashBookSummary';
import { LiveCompletedPosition } from 'src/app/models/LiveCompletedPosition';
import { StockAnalysis } from 'src/app/models/stock-analysis';
import { LiveCompletedPositionService } from 'src/app/services/live-completed-position.service';

@Component({
  selector: 'app-my-budget-stock-analysis',
  templateUrl: './my-budget-stock-analysis.component.html',
  styleUrls: ['./my-budget-stock-analysis.component.css']
})
export class MyBudgetStockAnalysisComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private liveCompletedPositionService: LiveCompletedPositionService,
    private datePipe: DatePipe,
    private spinnerService: NgxSpinnerService) { }

  errorMsg: string;
  searchForm: FormGroup;
  stockAnalysisData: StockAnalysis;
  liveCompletedPositions: LiveCompletedPosition[];
  budgetLCPositions: LiveCompletedPosition[] = [];
  cashBookSummary: CashBookSummary;

  ngOnInit() {
    this.createSearchForm();
    this.loadPositions();
  }

  loadPositions() {
    this.spinnerService.show();
    this.liveCompletedPositionService.getLiveCompletedPositions()
      .subscribe(
        data => {
          this.liveCompletedPositions = data;
        },
        err => {
          this.errorMsg = err;
          this.spinnerService.hide();
        },
        () => this.spinnerService.hide()
      )
  }

  loadBuggetStock() {
    let searchFromValue = this.searchForm.value;
    let amountPerStock = parseInt(searchFromValue.Amount);
    let fromDate = searchFromValue.FromDate;
    let toDate = searchFromValue.ToDate;

    this.budgetLCPositions = [];
    this.cashBookSummary = new CashBookSummary();
    this.cashBookSummary.TotalInvestment = 0;
    this.cashBookSummary.TotalProfit = 0;
    this.liveCompletedPositions.forEach(position => {

      // when stock amount is higher then per stock amount 
      if (position.Entry > amountPerStock) {
        return;
      }

      // when stock entry is before and after date range
      if (position.EntryDate < fromDate || position.EntryDate > toDate) {
        return;
      }

      let shareQty = Math.floor(amountPerStock / position.Entry);

      let lcPosition: LiveCompletedPosition = {
        StockName: position.StockName,
        PositionType: position.PositionType,
        Entry: position.Entry,
        EntryDate: position.EntryDate,
        StopLoss: position.StopLoss,
        LastTradePrice: position.LastTradePrice,
        ExitAmount: position.ExitAmount,
        ExitDate: position.ExitDate,
        StockCode: position.StockCode,
        Shares: shareQty,
        Capital: position.Entry * shareQty,
        CurrentValue: position.LastTradePrice * shareQty,
        BookedValue: null,
        ProfitOrLoss: null,
        ProfitOrLossPer: null
      };

      lcPosition.ProfitOrLoss = lcPosition.CurrentValue - lcPosition.Capital;
      lcPosition.ProfitOrLossPer = (lcPosition.ProfitOrLoss * 100) / lcPosition.Capital;

      if (lcPosition.ExitDate != null) {
        lcPosition.BookedValue = lcPosition.ProfitOrLoss;
        this.cashBookSummary.TotalInvestment -= lcPosition.CurrentValue;
      }


      this.cashBookSummary.TotalInvestment += lcPosition.Capital;
      this.cashBookSummary.TotalProfit += lcPosition.ProfitOrLoss;

      this.budgetLCPositions.push(lcPosition);


    })

  }

  createSearchForm() {
    let fromDate = new Date(2023, 0, 1);
    let toDate = new Date();

    this.searchForm = this.formBuilder.group({
      // UserId: [environment.userId, Validators.required],
      // Token: [environment.token, Validators.required],
      GroupName: ['Nifty50', Validators.required],
      StockName: ['', Validators.required],
      StockCode: ['', Validators.required],
      OtherStockCode: [''],
      TimeInterval: ['day', Validators.required],
      FromDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
      ToDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required],
      Amount: ['1000', Validators.required]
    })
  }

}
