import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, timer } from 'rxjs';
import { CashBookSummary } from 'src/app/models/CashBookSummary';
import { LiveCompletedPosition } from 'src/app/models/LiveCompletedPosition';
import { CompletedPosition } from 'src/app/models/completed-position';
import { LivePosition } from 'src/app/models/live-position';
import { HrCashBookService } from 'src/app/services/HrServices/hr-cash-book.service';
import { HrCompletedPositionService } from 'src/app/services/HrServices/hr-completed-position.service';
import { HrLivePositionService } from 'src/app/services/HrServices/hr-live-position.service';

@Component({
  selector: 'app-hr-equity',
  templateUrl: './hr-equity.component.html',
  styleUrls: ['./hr-equity.component.css']
})
export class HrEquityComponent implements OnInit {

  constructor(private livePositionService: HrLivePositionService,
    private completedPositionService: HrCompletedPositionService,
    private cashBookService: HrCashBookService,
    private spinnerService: NgxSpinnerService) { }

  showReportType: string;
  livePositions: LivePosition[] = [];
  completedPositions: CompletedPosition[] = [];
  liveCompletedPositions: LiveCompletedPosition[] = [];
  cashBookSummary: CashBookSummary;
  errorMsg: string = "";
  currentDate: Date;
  subscription: Subscription;

  ngOnInit() {
    this.showReportType = "All Position";
    this.currentDate = new Date();

    this.subscription = timer(0, 1000).subscribe(data => {
      this.currentDate = new Date();
    });

    this.loadPositions();
  }

  loadPositions() {
    this.spinnerService.show();

    let livePositionRequest = this.livePositionService.getAllLongPositions();
    let completedPositionRequest = this.completedPositionService.getAllLongPositions();
    let cashBookSummaryRequest = this.cashBookService.getCashBookSummary();

    forkJoin([livePositionRequest, completedPositionRequest 
      ,cashBookSummaryRequest
    ])
      .subscribe(
        data => {
          this.livePositions = data[0];
          this.completedPositions = data[1];
          this.cashBookSummary = data[2];
          this.combineLiveCompletedPosition();
        },
        err => {
          this.errorMsg = err;
          this.spinnerService.hide();
        },
        () => this.spinnerService.hide()
      )
  }

  combineLiveCompletedPosition() {

    let positions: LiveCompletedPosition[] = [];

    this.livePositions.forEach(x => {
      positions.push({
        StockName: x.StockName,
        PositionType: x.PositionType,
        Entry: x.Entry,
        EntryDate: x.EntryDate,
        StopLoss: x.StopLoss,
        LastTradePrice: x.LastTradePrice,
        ExitAmount: null,
        ExitDate: null,
        StockCode: x.StockCode,
        Shares: x.Shares,
        Capital: x.Capital,
        CurrentValue: x.CurrentValue,
        BookedValue: null,
        ProfitOrLoss: x.CurrentValue - x.Capital,
        ProfitOrLossPer: ((x.CurrentValue - x.Capital) * 100) / x.Capital

      });
    });

    this.completedPositions.forEach(x => {
      positions.push({
        StockName: x.StockName,
        PositionType: x.PositionType,
        Entry: x.Entry,
        EntryDate: x.EntryDate,
        StopLoss: null,
        LastTradePrice: x.ExitAmount,
        ExitAmount: x.ExitAmount,
        ExitDate: x.ExitDate,
        StockCode: x.StockCode,
        Shares: x.Shares,
        Capital: x.Capital,
        CurrentValue: null,
        BookedValue: x.BookedValue,
        ProfitOrLoss: x.BookedValue - x.Capital,
        ProfitOrLossPer: ((x.BookedValue - x.Capital) * 100) / x.Capital
      })
    })


    this.liveCompletedPositions = positions.sort((a, b) => {
      return this.getTime(b.EntryDate) - this.getTime(a.EntryDate);
    });
  }

  public getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }




}
