import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompletedPosition } from 'src/app/models/completed-position';
import { LivePosition } from 'src/app/models/live-position';
import { CompletedPositionService } from 'src/app/services/completed-position.service';
import { LivePositionService } from 'src/app/services/live-position.service';

@Component({
  selector: 'app-derivatives',
  templateUrl: './derivatives.component.html',
  styleUrls: ['./derivatives.component.css']
})
export class DerivativesComponent implements OnInit {

  constructor(private livePositionService: LivePositionService,
    private completedPositionService: CompletedPositionService,
    private spinnerService: NgxSpinnerService) { }

  showReportType: string;
  livePositions: LivePosition[] = [];
  completedPositions: CompletedPosition[] = [];
  errorMsg: string = "";

  ngOnInit() {
    this.showReportType = "Live Position";
    this.loadPositions();
    this.loadCompletedPositions();
  }


  loadPositions() {
    this.spinnerService.show();
    this.livePositionService.getAllShortPositions().subscribe(
      data => {
        this.livePositions = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }


  loadCompletedPositions() {
    this.spinnerService.show();
    this.completedPositionService.getAllShortPositions().subscribe(
      data => {
        this.completedPositions = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

}
