import { Injectable } from '@angular/core';
import { LivePositionService } from './live-position.service';
import { CompletedPositionService } from './completed-position.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { LivePosition } from '../models/live-position';
import { CompletedPosition } from '../models/completed-position';
import { LiveCompletedPosition } from '../models/LiveCompletedPosition';

@Injectable({
  providedIn: 'root'
})
export class LiveCompletedPositionService {

  constructor(private livePositionService: LivePositionService,
    private completedPositionService: CompletedPositionService) { }


  getLiveCompletedPositions() {

    let livePositionRequest = this.livePositionService.getAllLongPositions();
    let completedPositionRequest = this.completedPositionService.getAllLongPositions();

    return forkJoin([livePositionRequest, completedPositionRequest])
      .pipe<LiveCompletedPosition[]>(map(data => {
        let livePositions = data[0];
        let completedPositions = data[1];
        return this.combineLiveCompletedPosition(livePositions, completedPositions);
      }));
  }

  private combineLiveCompletedPosition(livePositions: LivePosition[],
    completedPositions: CompletedPosition[]): LiveCompletedPosition[] {

    let positions: LiveCompletedPosition[] = [];

    livePositions.forEach(x => {
      positions.push({
        StockName: x.StockName,
        PositionType: x.PositionType,
        Entry: x.Entry,
        EntryDate: x.EntryDate,
        StopLoss: x.StopLoss,
        LastTradePrice: x.LastTradePrice,
        ExitAmount: null,
        ExitDate: null,
        StockCode: x.StockCode,
        Shares: x.Shares,
        Capital: x.Capital,
        CurrentValue: x.CurrentValue,
        BookedValue: null,
        ProfitOrLoss: x.CurrentValue - x.Capital,
        ProfitOrLossPer: ((x.CurrentValue - x.Capital) * 100) / x.Capital

      });
    });

    completedPositions.forEach(x => {
      positions.push({
        StockName: x.StockName,
        PositionType: x.PositionType,
        Entry: x.Entry,
        EntryDate: x.EntryDate,
        StopLoss: null,
        LastTradePrice: x.ExitAmount,
        ExitAmount: x.ExitAmount,
        ExitDate: x.ExitDate,
        StockCode: x.StockCode,
        Shares: x.Shares,
        Capital: x.Capital,
        CurrentValue: null,
        BookedValue: x.BookedValue,
        ProfitOrLoss: x.BookedValue - x.Capital,
        ProfitOrLossPer: ((x.BookedValue - x.Capital) * 100) / x.Capital
      })
    })


    let liveCompletedPositions = positions.sort((a, b) => {
      return this.getTime(b.EntryDate) - this.getTime(a.EntryDate);
    });

    return liveCompletedPositions;
  }

  private getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }

}
