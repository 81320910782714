import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { StockAnalysis } from 'src/app/models/stock-analysis';
import { StockConfig } from 'src/app/models/stock-config';
import { StockAnalysisService } from 'src/app/services/stock-analysis.service';
import { StockConfigService } from 'src/app/services/stock-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-stock-analysis',
  templateUrl: './single-stock-analysis.component.html',
  styleUrls: ['./single-stock-analysis.component.css']
})
export class SingleStockAnalysisComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private stockConfigService: StockConfigService,
    private stockAnalysisService: StockAnalysisService,
    private spinnerService: NgxSpinnerService) { }

  searchForm: FormGroup;
  errorMsg: string;
  showReportType: string = 'Trade Position';
  stockConfigList: StockConfig[] = [];
  stockConfigByGroupList: StockConfig[] = [];
  stockAnalysisData: StockAnalysis;

  ngOnInit() {
    this.createSearchForm();
    this.loadStockConfig();
  }


  loadStockConfig() {

    this.spinnerService.show();
    this.stockConfigService.getAll().subscribe(
      data => {
        this.stockConfigList = data;
        this.stockConfigByGroupList = this.stockConfigList;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  loadStockAnalysis() {
    let searchValue = this.searchForm.value;

    this.stockAnalysisData = null;
    this.spinnerService.show();
    this.stockAnalysisService.get(searchValue).subscribe(
      data => {
        this.stockAnalysisData = data;
      },
      err => {
        this.errorMsg = err;
        this.spinnerService.hide();
      },
      () => this.spinnerService.hide()
    )
  }

  changeGroup(event) {
    if (event.target.value == "Individual") {
      this.searchForm.controls.StockCode.setValue('');
      return;
    }
    this.stockConfigByGroupList = this.stockConfigList;
  }

  selectStock($event) {
    this.searchForm.controls.StockCode.setValue($event.Code);
  }


  totalTradePoints() {
    return this.stockAnalysisData.TradePositions.reduce((a, b) => a + b.TradePoints, 0);
  }

  totalROI() {
    return this.stockAnalysisData.TradePositions.reduce((a, b) => a + b.ROI, 0);
  }


  totalTradeDays() {
    return (this.stockAnalysisData.TradePositions.reduce((a, b) => a + b.TotalTradeDays, 0) / 365);
  }

  totalROIPerYear() {
    var totalYear = this.totalTradeDays();
    if (totalYear < 1) {
      return this.totalROI();
    }
    return this.totalROI() / this.totalTradeDays();
  }


  createSearchForm() {
    let fromDate = new Date(2020, 0, 1);
    let toDate = new Date();

    // console.log(fromDate.toString());

    this.searchForm = this.formBuilder.group({
      // UserId: [environment.userId, Validators.required],
      // Token: [environment.token, Validators.required],
      GroupName: ['Nifty50', Validators.required],
      StockName: ['', Validators.required],
      StockCode: ['', Validators.required],
      OtherStockCode: [''],
      TimeInterval: ['day', Validators.required],
      FromDate: [this.datePipe.transform(fromDate, 'y-MM-dd'), Validators.required],
      ToDate: [this.datePipe.transform(toDate, 'y-MM-dd'), Validators.required]
    })
  }

}
