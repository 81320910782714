import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { LivePosition } from 'src/app/models/live-position';
import { LivePositionService } from 'src/app/services/live-position.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-live-position',
  templateUrl: './live-position.component.html',
  styleUrls: ['./live-position.component.css']
})
export class LivePositionComponent implements OnInit {

  constructor() { }

  @Input() livePositions: LivePosition[];
  @Input() positionType: string;

  //livePositions: LivePosition[];
  errorMsg: string = "";
  kitestockUrl: string;

  ngOnInit() {
    this.kitestockUrl = environment.kiteStockUrl
  }


  totalReturn() {

    var totalSumPer = 0;
    if (this.positionType == 'Long') {
      this.livePositions.forEach(livePosition => {
        totalSumPer += ((livePosition.LastTradePrice - livePosition.Entry) / livePosition.Entry) * 100
      });
    }
    else {
      this.livePositions.forEach(livePosition => {
        totalSumPer += ((livePosition.Entry - livePosition.LastTradePrice) / livePosition.Entry) * 100
      });
    }
    return totalSumPer;
  }

  getColor(livePosition: LivePosition) {

    return livePosition.EntryDate == new Date();

  }

  getDateFormat() {
    return 'dd-MMM-yy';
  }

  getChange(livePosition: LivePosition) {
    if (livePosition.PositionType == "Long") {
      return ((livePosition.LastTradePrice - livePosition.Entry) / livePosition.Entry) * 100;
    } else if (livePosition.PositionType == "Short") {
      return ((livePosition.Entry - livePosition.LastTradePrice) / livePosition.Entry) * 100;
    }

  }

}
