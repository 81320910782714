import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SingleStockAnalysisComponent } from './components/single-stock-analysis/single-stock-analysis.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HistoryDataGridComponent } from './components/history-data-grid/history-data-grid.component';
import { DowDataGridComponent } from './components/dow-data-grid/dow-data-grid.component';
import { TradePositionGridComponent } from './components/trade-position-grid/trade-position-grid.component';
import { ScanStockTodayEntryComponent } from './components/scan-stock-today-entry/scan-stock-today-entry.component';
import { HomeComponent } from './components/home/home.component';
import { LivePositionComponent } from './components/live-position/live-position.component';
import { CompletedPositionComponent } from './components/completed-position/completed-position.component';
import { NgxSpinnerModule } from "ngx-spinner";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { EquityComponent } from './components/equity/equity.component';
import { DerivativesComponent } from './components/derivatives/derivatives.component';
import { StockAnalysisReportComponent } from './components/stock-analysis-report/stock-analysis-report.component';
import { LiveCompletedPositionComponent } from './components/live-completed-position/live-completed-position.component';
import { HrEquityComponent } from './components/_hr-components/hr-equity/hr-equity.component';
import { ExcelBackTestComponent } from './components/excel-back-test/excel-back-test.component';
import { MyBudgetStockAnalysisComponent } from './components/my-budget-stock-analysis/my-budget-stock-analysis.component';
import { AmzLoginComponent } from './components/_Amazon/amz-login/amz-login.component';

@NgModule({
  declarations: [
    AppComponent,
    SingleStockAnalysisComponent,
    HistoryDataGridComponent,
    DowDataGridComponent,
    TradePositionGridComponent,
    ScanStockTodayEntryComponent,
    HomeComponent,
    LivePositionComponent,
    CompletedPositionComponent,
    EquityComponent,
    DerivativesComponent,
    StockAnalysisReportComponent,
    LiveCompletedPositionComponent,
    HrEquityComponent,
    ExcelBackTestComponent,
    MyBudgetStockAnalysisComponent,
    AmzLoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AutocompleteLibModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
