import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompletedPosition } from 'src/app/models/completed-position';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HrCompletedPositionService {

  
  constructor(private httpClient: HttpClient) { }

  ServiceUrl: string = environment.BaseServiceUrl + "HrCompletedPosition";
  
  public getAllLongPositions(): Observable<CompletedPosition[]> {
    let url=this.ServiceUrl+'/long';
    return this.httpClient.get<CompletedPosition[]>(url);
  }

  public getAllShortPositions(): Observable<CompletedPosition[]> {
    let url=this.ServiceUrl+'/short';
    return this.httpClient.get<CompletedPosition[]>(url);
  }
}
